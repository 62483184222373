import React from "react";

import { Route } from "react-router";

import Mondrian from "./mondrian/index";
import Siena from "./siena/index";
import Portinari from "./portinari/index";

function MundoApto() {
  return (
    <>
      <Route path="/souza-araujo/mondrian">
        <Mondrian />
      </Route>

      <Route path="/souza-araujo/siena">
        <Siena />
      </Route>

      <Route path="/souza-araujo/portinari">
        <Portinari />
      </Route>
    </>
  );
}

export default MundoApto;
