import * as S from "./styles";

function MenuButton({ label, image, active, action, ...rest }) {
  return (
    <S.Wrapper onClick={action} active={active}>
      {/* <S.Icon src={image} /> */}

      {image}
      <S.Label>{label}</S.Label>
    </S.Wrapper>
  );
}

export default MenuButton;
