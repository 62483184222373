import React from "react";

import { Route } from "react-router";

import Agata from "./agata/index";
import Habitat from "./habitat/index";

function Map() {
  return (
    <>
      <Route path="/herc/agata">
        <Agata />
      </Route>

      <Route path="/herc/habitat">
        <Habitat />
      </Route>
    </>
  );
}

export default Map;
