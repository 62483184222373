import React from "react";

import { Route } from "react-router";

import Pacaembu from "./Pacaembu";
import PracaSeCentral from "./PracaSeCentral";
import Locus from "./locus";
import VilaMatilde from "./VilaMatilde";
import Jabaquara from "./jabaquara";
import Freguesia from "./freguesia";
import CampoBelo from "./campo-belo";
import BarraFunda from "./barra-funda";

function MundoApto() {
  return (
    <>
      <Route path="/mundoapto/pracase">
        <PracaSeCentral />
      </Route>
      <Route path="/mundoapto/locus">
        <Locus />
      </Route>
      <Route path="/mundoapto/pacaembu">
        <Pacaembu />
      </Route>
      <Route path="/mundoapto/freguesia">
        <Freguesia />
      </Route>
      <Route path="/mundoapto/vilamatilde">
        <VilaMatilde />
      </Route>
      <Route path="/mundoapto/barra-funda">
        <BarraFunda />
      </Route>
      <Route path="/mundoapto/jabaquara">
        <Jabaquara />
      </Route>
      <Route path="/mundoapto/campo-belo">
        <CampoBelo />
      </Route>
    </>
  );
}

export default MundoApto;
