import React from "react";

import { Route } from "react-router";

import Solenne from "./solenne/index";

function MundoApto() {
  return (
    <>
      <Route path="/piemonte/solenne">
        <Solenne />
      </Route>
    </>
  );
}

export default MundoApto;
