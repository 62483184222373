import React from "react";
import { Route } from "react-router-dom";

import HighlightsBlue from "./HighlightsBlue";
import HighlightsOrgange from "./HighlightsOrgange";
import JardimPrudencia from "./JardimPrudencia";
import Florear from "./florear";
import Unik from "./unik";

function TecnisaHighlights() {
  return (
    <>
      <Route path="/tecnisa/highlights/orange">
        <HighlightsOrgange />
      </Route>
      <Route path="/tecnisa/highlights/blue">
        <HighlightsBlue />
      </Route>
      <Route path="/tecnisa/highlights/jardim-prudencia">
        <JardimPrudencia />
      </Route>
      <Route path="/tecnisa/highlights/florear">
        <Florear />
      </Route>
      <Route path="/tecnisa/highlights/unik">
        <Unik />
      </Route>
    </>
  );
}

export default TecnisaHighlights;
