import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: Roboto, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue'; */
}

.mapContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

@keyframes jump {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes _ibani_1434 {
  0% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1.2);
  }
  90% {
   -webkit-transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
  }
}


/* .leaflet-pane .leaflet-marker-icon {
  opacity: 0;
  animation: _ibani_1434 0.2s forwards;
  animation-delay: 1s;
}

.leaflet-tooltip{
  opacity: 0;
  animation: jump 0.5s forwards;
} */

.leaflet-container .leaflet-control-attribution, .leaflet-container .leaflet-control-scale {
  font-size: 10px;
  display: flex;
  flex-direction: row;
  position: fixed;
  left: 0;
  bottom: 0;
}

.direcional {
  background: #444444;
}

.direcional button p {
  color: #fff;
}

`;

export default GlobalStyles;
