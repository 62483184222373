import areaverde from "./assets/icons/areasverdes.svg";
import academias from "./assets/icons/academias.svg";
import gastronomia from "./assets/icons/gastronomia.svg";
import mercados from "./assets/icons/mercados.svg";
import padaria from "./assets/icons/padarias.svg";
import servicos from "./assets/icons/servicos.svg";
import shoppings from "./assets/icons/shoppings.svg";
import viasprincipais from "./assets/icons/viasprincipais.svg";
import aeroporto from "./assets/icons/aeroporto.svg";
import metro from "./assets/icons/metro.svg";
import escola from "./assets/icons/escola.svg";
import clube from "./assets/icons/clubes.svg";
import futebol from "./assets/icons/futebol.svg";
import igrejas from "./assets/icons/igrejas.svg";
import hospitais from "./assets/icons/hospitais.svg";
import mapmarker from "./assets/icons/mapmarker.svg";

const AreaVerde = () => <img src={areaverde} />;

const Aeroporto = () => <img src={aeroporto} />;

const Gastronomia = () => <img src={gastronomia} />;

const Mercado = () => <img src={mercados} />;

const Padaria = () => <img src={padaria} />;

const Servicos = () => <img src={servicos} />;

const Shoppings = () => <img src={shoppings} />;

const ViasPrincipais = () => <img src={viasprincipais} />;

const Escola = () => <img src={escola} />;

const Academias = () => <img src={academias} />;

const Hospitais = () => <img src={hospitais} />;

export const getIconInline = {
  areaverde: <AreaVerde />,
  gastronomia: <Gastronomia />,
  mercados: <Mercado />,
  padaria: <Padaria />,
  servicos: <Servicos />,
  shoppings: <Shoppings />,
  viasprincipais: <ViasPrincipais />,
  aeroporto: <Aeroporto />,
  escola: <Escola />,
  academias: <Academias />,
  hospitais: <Hospitais />,
  mapmarker: <img style={{ width: "50px" }} src={mapmarker} />,
};

export const getIcon = {
  areaverde: areaverde,
  gastronomia: gastronomia,
  mercados: mercados,
  padaria: padaria,
  servicos: servicos,
  shoppings: shoppings,
  viasprincipais: viasprincipais,
  aeroporto: aeroporto,
  metro: metro,
  escola: escola,
  clube: clube,
  futebol: futebol,
  igrejas: igrejas,
  academias: academias,
  hospitais: hospitais,
  mapmarker: mapmarker,
};
