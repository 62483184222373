import React from "react";

import { Route } from "react-router";

import CantoDaMata from "./canto-da-mata/index";
import AltoDaMata from "./alto-da-mata/index";
import AlphaOne from "./alpha-one/index";
import GranjaViana3 from "./granja-viana-3/index";

function MundoApto() {
  return (
    <>
      <Route path="/ekko-group/canto-da-mata">
        <CantoDaMata />
      </Route>

      <Route path="/ekko-group/alto-da-mata">
        <AltoDaMata />
      </Route>

      <Route path="/ekko-group/alpha-one">
        <AlphaOne />
      </Route>

      <Route path="/ekko-group/granja-viana-3">
        <GranjaViana3 />
      </Route>
    </>
  );
}

export default MundoApto;
