import React from "react";
import FloatingMenu from "../../components/FloatingMenu";
import MapView from "../../components/MapView";
import Provider, { LocationsContext } from "./context";

import logo from "./assets/logo-circle.svg";
import logoEmpreendimento from "./assets/logo-empreendimento.png";

import LocalStyles from "./styles";
import { getIcon, getIconInline } from "../../icons";

const Logo = () => <img style={{ height: "100%" }} src={logoEmpreendimento} />;

function Piemonte() {
  return (
    <Provider>
      <MapView
        locationsContext={LocationsContext}
        logo={logo}
        getIcon={getIcon}
      >
        <FloatingMenu
          locationsContext={LocationsContext}
          logo={<Logo />}
          getIconInline={getIconInline}
        />
      </MapView>

      <LocalStyles />
    </Provider>
  );
}

export default Piemonte;
