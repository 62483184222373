import React from "react";

import { Route } from "react-router";

import Highlights from "./highlights";
import Spotlights from "./spotlights";

function Pinheiros() {
  return (
    <>
      <Route path="/pinheiros/highlights">
        <Highlights />
      </Route>
      <Route path="/pinheiros/spotlights">
        <Spotlights />
      </Route>
    </>
  );
}

export default Pinheiros;
