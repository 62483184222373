import React from "react";
import { Route } from "react-router-dom";

import Satelite from "./satelite";

function TecnisaHighlights() {
  return (
    <>
      <Route path="/rni/satelite">
        <Satelite />
      </Route>
    </>
  );
}

export default TecnisaHighlights;
