import styled, { css } from "styled-components";

export const Wrapper = styled.button`
  ${({ active }) => css`
    border: 0;
    outline: none;
    background: transparent;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    transition: filter 0.5s ease-in-out;

    filter: ${!active ? "grayscale(1); color: #6B7280" : "grayscale(0)"};
  `}
`;

export const Label = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.font.normal};
    margin-left: 10px;
    font-size: ${theme.font.sizes.xsmall};
  `}
`;

export const Icon = styled.img`
  width: 30px;
`;
