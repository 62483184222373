import { useContext } from "react";
import { MapContainer, TileLayer } from "react-leaflet";

import "leaflet/dist/leaflet.css";

import MarkerMap from "../MarkerMap";

import "./styles.css";

function MapView({ children, locationsContext, logo, getIcon }) {
  const {
    locations,
    categories,
    center,
    mapConfig,
    tileLayer,
    discount,
    titlePage,
    locationsConfig,
  } = useContext(locationsContext);
  const empreendimento = center;
  const mapCenter = center.latlng;
  const limits = [
    [mapCenter[0] + center.limit, mapCenter[1] + center.limit],
    [mapCenter[0] - center.limit, mapCenter[1] - center.limit],
  ];

  document.title = titlePage;

  return (
    <>
      <MapContainer
        center={mapCenter}
        minZoom={center.minZoom}
        maxBoundsViscosity={0.8}
        zoom={center.zoom}
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: mapConfig.backgroundColor,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        maxBounds={limits}
        attributionControl={true}
      >
        <TileLayer
          url={tileLayer}
          attribution='&copy <a href="https://www.openstreetmap.org/">OpenStreetMap</a> &copy <a href="https://www.mapbox.com/">Mapbox</a>'
        />
        {locations.map(
          (item, index) =>
            categories[item.id].active && (
              <MarkerMap
                key={index}
                location={item}
                icon={getIcon[categories[item.id].icon]}
                zIndexOffset={-1}
                discount={discount}
                tooltipDirection={item?.tooltipDirection}
                tooltipStyle={item?.tooltipStyle}
              />
            )
        )}
        {locationsConfig?.resetPoints.map((point, index) => (
          <MarkerMap
            key={`${index}_${point.point}`}
            location={point}
            icon={logo}
            zIndexOffset={1000}
            classNameTooltip="z-index-1000"
            discount={discount}
            tooltipDirection={point?.tooltipDirection}
            // accentColor={center.accentColor}
            tooltipStyle={point?.tooltipStyle}
          />
        ))}
        {!locationsConfig?.resetPoints && (
          <MarkerMap
            location={empreendimento}
            icon={logo}
            zIndexOffset={1000}
            classNameTooltip="z-index-1000"
            discount={discount}
            tooltipDirection={empreendimento?.tooltipDirection}
            // accentColor={center.accentColor}
            tooltipStyle={empreendimento?.tooltipStyle}
          />
        )}
        {children}
      </MapContainer>
    </>
  );
}

export default MapView;
