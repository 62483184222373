import React from "react";

import { Route } from "react-router";

import Habitat from "./habitat/index";

function MundoApto() {
  return (
    <>
      <Route path="/herc/habitat">
        <Habitat />
      </Route>
    </>
  );
}

export default MundoApto;
