import React from "react";
import FloatingMenu from "../../../components/FloatingMenu";
import MapView from "../../../components/MapView";
import Provider, { LocationsContext } from "./context";

import logo from "./assets/logo-circle.svg";

import logoMenu from "./assets/logo.jpg";

import LocalStyles from "./styles";
import { getIcon, getIconInline } from "./icons";

const Logo = () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <img src={logoMenu} />
  </div>
);

function Map() {
  return (
    <Provider>
      <MapView
        locationsContext={LocationsContext}
        logo={logo}
        getIcon={getIcon}
      >
        <FloatingMenu
          locationsContext={LocationsContext}
          logo={<Logo />}
          getIconInline={getIconInline}
        />
      </MapView>

      <LocalStyles />
    </Provider>
  );
}

export default Map;
