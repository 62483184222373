import React from "react";

import { Route } from "react-router";

import MMCortes from "./bella-italia/index";

function MundoApto() {
  return (
    <>
      <Route path="/mm-cortes/bella-italia">
        <MMCortes />
      </Route>
    </>
  );
}

export default MundoApto;
