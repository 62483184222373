import { createGlobalStyle } from "styled-components";
import * as FloatingMenuStyles from "../../../components/FloatingMenu/styles";

const LocalStyles = createGlobalStyle`
  ${FloatingMenuStyles.ResetWrapper}{
    background-color: #ec835f
  }

  .leaflet-tooltip {
    background: #F4F5F7;
    color: #000;
  }

  .leaflet-tooltip::before {
    border-top-color: #F4F5F7;
  }

`;

export default LocalStyles;
