import styled, { css } from "styled-components";

import * as buttonStyles from "../MenuButton/styles";

const wrapperModifiers = {
  bottomMenu: () => css`
    display: flex;
    flex-direction: row;
    bottom: 20px;
  `,
  sideMenu: () => css`
    display: flex;
    flex-direction: column;
    right: 0;

    ${ResetWrapper} {
      padding: 10px 13px 10px 13px;
    }
  `,
};

export const Wrapper = styled.nav`
  position: absolute;
  z-index: 500;
  ${({ sideMenu, backgroundColor }) => css`
    ${!!sideMenu ? wrapperModifiers.sideMenu() : wrapperModifiers.bottomMenu()}
    background-color: ${!!backgroundColor ? backgroundColor : "#fff"};
  `}
`;

export const ResetWrapper = styled.div`
  ${({ backgroundColor }) => css`
    background: ${!!backgroundColor ? backgroundColor : "#fff"};
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  `}

  &:not(:first-child) {
    margin-left: 0.6rem;
  }
`;

export const LogoTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const WrapperLogo = styled.div`
  /* margin-bottom: -5px; */
  display: flex;
`;

export const LocationTextWrapper = styled.div`
  background: rgba(100, 98, 98, 0.9);
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px 0;
  font-size: 12px;

  small {
    color: #fff;
  }
`;

export const WrapperTitle = styled.div`
  width: 100%;
  padding: 0 10px;
`;

export const PointName = styled.a`
  font-weight: 400;
  font-size: 1.2rem;
  text-decoration: none;
  color: black !important;
`;

const buttonsWrapperModifiers = {
  bottomMenu: () => css`
    flex-direction: row;
  `,
  sideMenu: () => css`
    flex-direction: column;
    padding: 0;

    ${buttonStyles.Wrapper} {
      margin: 0;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 15px;
      padding-right: 15px;
    }
  `,
};

export const ButtonsWrapper = styled.div`
  display: flex;
  padding: 0 20px;

  ${({ sideMenu }) => css`
    ${!!sideMenu
      ? buttonsWrapperModifiers.sideMenu()
      : buttonsWrapperModifiers.bottomMenu()}
  `}
`;
