import React from "react";
import FloatingMenu from "../../components/FloatingMenu";
import MapView from "../../components/MapView";
import Provider, { LocationsContext } from "./context";

import logo from "./assets/logo-garfisa-circle.svg";

import LocalStyles from "./styles";
import { getIcon, getIconInline } from "./icons";

const Logo = () => (
  <div
    style={{
      height: "85px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0px 10px",
    }}
  >
    <svg
      width="40"
      height="35"
      viewBox="0 0 40 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2365 5.9466L19.751 0L0 35H40L24.8963 8.83495L15.6017 25.8252H22.2407L24.8963 21.068L29.3776 28.8835H10.4564L23.2365 5.9466Z"
        fill="#a30037"
      />
    </svg>
  </div>
);

function GafisaIbirapueraPark() {
  return (
    <Provider>
      <MapView
        locationsContext={LocationsContext}
        logo={logo}
        getIcon={getIcon}
      >
        <FloatingMenu
          locationsContext={LocationsContext}
          logo={<Logo />}
          getIconInline={getIconInline}
        />
      </MapView>

      <LocalStyles />
    </Provider>
  );
}

export default GafisaIbirapueraPark;
