import React from "react";

import { Route } from "react-router";

import Croma from "./croma/index";

function MundoApto() {
  return (
    <>
      <Route path="/piemonte/croma">
        <Croma />
      </Route>
    </>
  );
}

export default MundoApto;
