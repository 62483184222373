import areaverde from "./assets/icons/areaverde.svg";
import aeroporto from "./assets/icons/aeroporto.svg";
import academias from "./assets/icons/academias.svg";
import conveniencia from "./assets/icons/conveniencia.svg";
import metroverde from "./assets/icons/metroverde.svg";
import metroamarelo from "./assets/icons/metroamarelo.svg";
import compras from "./assets/icons/compras.svg";
import hospitais from "./assets/icons/hospitais.svg";
import escola from "./assets/icons/escola.svg";
import gastronomia from "./assets/icons/gastronomia.svg";
import mercados from "./assets/icons/mercados.svg";
import viasprincipais from "./assets/icons/viasprincipais.svg";
import ciclovia from "./assets/icons/ciclovia.svg";
import futebol from "./assets/icons/futebol.svg";
import museu from "./assets/icons/museu.svg";

export const getIconInline = {
  areaverde: <img src={areaverde}></img>,
  aeroporto: <img src={aeroporto}></img>,
  gastronomia: <img src={gastronomia}></img>,
  escola: <img src={escola}></img>,
  academias: <img src={academias}></img>,
  conveniencia: <img src={conveniencia}></img>,
  mercados: <img src={mercados}></img>,
  viasprincipais: <img src={viasprincipais}></img>,
  ciclovia: <img src={ciclovia}></img>,
  metroverde: <img src={metroverde}></img>,
  metroamarelo: <img src={metroamarelo}></img>,
  compras: <img src={compras}></img>,
  hospitais: <img src={hospitais}></img>,
  futebol: <img src={futebol}></img>,
  museu: <img src={museu}></img>,
};

export const getIcon = {
  areaverde: areaverde,
  aeroporto: aeroporto,
  gastronomia: gastronomia,
  escola: escola,
  academias: academias,
  conveniencia: conveniencia,
  mercados: mercados,
  viasprincipais: viasprincipais,
  ciclovia: ciclovia,
  metroverde: metroverde,
  metroamarelo: metroamarelo,
  compras: compras,
  hospitais: hospitais,
  futebol: futebol,
  museu: museu,
};
