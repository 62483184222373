import React from "react";
import FloatingMenu from "../../../components/FloatingMenu";
import MapView from "../../../components/MapView";
import Provider, { LocationsContext } from "./context";

import logo from "./assets/logo-circle.svg";

import LocalStyles from "./styles";
import { getIcon, getIconInline } from "./icons";

const Logo = () => (
  <div
    style={{
      padding: "10px",
      width: "100%",
      height: "85px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <svg width="28" height="40" viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 0C6.26 0 0 6.26 0 14C0 24.5 14 40 14 40C14 40 28 24.5 28 14C28 6.26 21.74 0 14 0ZM14 19C11.24 19 9 16.76 9 14C9 11.24 11.24 9 14 9C16.76 9 19 11.24 19 14C19 16.76 16.76 19 14 19Z" fill="black"/>
    </svg>
  </div>
);

function PracaSeCentral() {
  return (
    <Provider>
      <MapView
        locationsContext={LocationsContext}
        logo={logo}
        getIcon={getIcon}
      >
        <FloatingMenu
          locationsContext={LocationsContext}
          logo={<Logo />}
          getIconInline={getIconInline}
        />
      </MapView>
      <LocalStyles />
    </Provider>
  );
}

export default PracaSeCentral;
