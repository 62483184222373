import { createGlobalStyle } from "styled-components";

const LocalStyles = createGlobalStyle`
  * {
      font-family: Arial, Helvetica, sans-serif
  }

  nav img[style] {
    height: 90px !important;
  }

  .hcAqIA {
    flex-direction: column;
    padding-top: 15px;
  }

  .hcAqIA img, .hcAqIA svg {
    margin-bottom: 5px;
  }

  .hcAqIA p {
    font-size: 1rem;
    margin-left: 0;
  }

  .ifmKUT {
    flex-direction: column;
    padding-top: 15px;
  }

  .ifmKUT img, .ifmKUT svg {
    margin-bottom: 5px;
  }

  .ifmKUT p {
    font-size: 1rem;
    margin-left: 0;
  }
`;

export default LocalStyles;
