import React from "react";
import FloatingMenu from "../../components/FloatingMenu";
import MapView from "../../components/MapView";
import Provider, { LocationsContext } from "./context";

import logo from "./assets/logo-circle.png";
import logoInc from "./assets/logoinc.jpg";

import LocalStyles from "./styles";
import { getIcon, getIconInline } from "./icons";

const Logo = () => <img style={{ height: "85px" }} src={logoInc} />;

function YouInc() {
  return (
    <Provider>
      <MapView
        locationsContext={LocationsContext}
        logo={logo}
        getIcon={getIcon}
      >
        <FloatingMenu
          locationsContext={LocationsContext}
          logo={<Logo />}
          getIconInline={getIconInline}
          information="Rua Curitiba, 2142 - Lourdes"
        />
      </MapView>

      <LocalStyles />
    </Provider>
  );
}

export default YouInc;
