import React from "react";
import FloatingMenu from "../../../components/FloatingMenu";
import MapView from "../../../components/MapView";
import Provider, { LocationsContext } from "./context";

import { getIcon, getIconInline } from "./icons";

import logoLaranja from "./assets/logosquare.jpg";
import logoroud from "./assets/logoroud.png";

const LogoLaranja = () => (
  <div
    style={{
      height: "100%",
      width: "14rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <img
      src={logoLaranja}
      style={{
        width: "100%",
      }}
    />
  </div>
);

function TecnisaHighlights() {
  return (
    <Provider>
      <MapView
        locationsContext={LocationsContext}
        logo={logoroud}
        getIcon={getIcon}
      >
        <FloatingMenu
          locationsContext={LocationsContext}
          logo={<LogoLaranja />}
          getIconInline={getIconInline}
          sideMenu
        />
      </MapView>
      {/* <LocalStyles /> */}
    </Provider>
  );
}

export default TecnisaHighlights;
