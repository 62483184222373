import areaverde from "../../../assets/icons/areasverdes.svg";
import gastronomia from "../../../assets/icons/gastronomia.svg";
import servicos from "../../../assets/icons/servicos.svg";
import shoppings from "../../../assets/icons/shoppings.svg";
import viasprincipais from "../../../assets/icons/viasprincipais.svg";
import aeroporto from "../../../assets/icons/aeroporto.svg";
import metro from "../../../assets/icons/metro.svg";
import clube from "../../../assets/icons/clubes.svg";
import futebol from "../../../assets/icons/futebol.svg";

import padaria from "../assets/icons/padaria.svg";
import mercados from "../assets/icons/mercados.svg";
import escola from "../assets/icons/escola.svg";

export const getIconInline = {
  areaverde: <img src={areaverde} />,
  gastronomia: <img src={gastronomia} />,
  mercados: <img src={mercados} />,
  padaria: <img src={padaria} />,
  servicos: <img src={servicos} />,
  shoppings: <img src={shoppings} />,
  viasprincipais: <img src={viasprincipais} />,
  aeroporto: <img src={aeroporto} />,
  escola: <img src={escola} />,
};

export const getIcon = {
  areaverde: areaverde,
  gastronomia: gastronomia,
  mercados: mercados,
  padaria: padaria,
  servicos: servicos,
  shoppings: shoppings,
  viasprincipais: viasprincipais,
  aeroporto: aeroporto,
  metro: metro,
  escola: escola,
  clube: clube,
  futebol: futebol,
};
