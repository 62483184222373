import { useContext, useEffect, useState } from "react";
import MenuButton from "../MenuButton";
import { useMap } from "react-leaflet";

import * as S from "./styles";

function FloatingMenu({
  logo,
  locationsContext,
  getIconInline,
  sideMenu = false,
}) {
  const { title, categories, toggleCategoryVisibility, center, locationsConfig } =
    useContext(locationsContext);
  const [buttons, setButtons] = useState([]);
  const map = useMap();

  useEffect(() => {
    setButtons(Object.keys(categories));
    const buttonsWithData = Object.keys(categories).map(
      (item) => categories[item]
    );
    setButtons(buttonsWithData);
  }, []);

  return (
    <S.Wrapper sideMenu={sideMenu} style={{ zIndex: 1000 }} backgroundColor={center.secondaryColor}>
      {locationsConfig?.resetPoints.map((point, index) => (
        <S.ResetWrapper
          key={`${index}_${point.point}`}
          onClick={() => map.flyTo(point.latlng, center.zoom)}
          backgroundColor={center.primaryColor}
        >
          <S.LogoTitle>
            <S.WrapperLogo>
              { point?.image ? '' : logo }
            </S.WrapperLogo>
            {!!point.name && (
              <S.WrapperTitle>
                <S.PointName>{point.name}</S.PointName>
              </S.WrapperTitle>
            )}
          </S.LogoTitle>
        </S.ResetWrapper>
      ))}

      {!!locationsConfig?.asdressInfo && (
        <S.LocationTextWrapper>
          <small>{locationsConfig.asdressInfo}</small>
        </S.LocationTextWrapper>
      )}
      
      {!locationsConfig?.resetPoints && (
        <S.ResetWrapper
          onClick={() => map.flyTo(center.latlng, center.zoom)}
          backgroundColor={center.primaryColor}
        >
          { logo }
        </S.ResetWrapper>
      )}
      <S.ButtonsWrapper className={ `${ locationsConfig?.style }` } sideMenu={sideMenu}>
        {buttons.map((item, index) => {
          if (item.onMenu)
            return (
              <MenuButton
                key={item.id + index}
                label={item.label}
                image={getIconInline[item.icon]}
                action={() => toggleCategoryVisibility(item.id)}
                active={categories[item.id].active}
              />
            );
          return null;
        })}
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
}

export default FloatingMenu;
