import React from "react";
import FloatingMenu from "../../../components/FloatingMenu";
import MapView from "../../../components/MapView";
import Provider, { LocationsContext } from "./context";

import { getIcon, getIconInline } from "./icons";

import LocalStyles from "../styles/orange";

import logoLaranja from "../assets/tecnisa-laranja.png";

const LogoLaranja = () => (
  <div
    style={{
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 10px",
    }}
  >
    <img src={logoLaranja} />
  </div>
);

function TecnisaHighlights() {
  return (
    <Provider>
      <MapView
        locationsContext={LocationsContext}
        logo={logoLaranja}
        getIcon={getIcon}
      >
        <FloatingMenu
          locationsContext={LocationsContext}
          logo={<LogoLaranja />}
          getIconInline={getIconInline}
          sideMenu
        />
      </MapView>
      <LocalStyles />
    </Provider>
  );
}

export default TecnisaHighlights;
