import React from "react";

import { Route } from "react-router";

import JardimDosManacas from "./jardim-dos-manacas/index";

function MundoApto() {
  return (
    <>
      <Route path="/piemonte/jardim-dos-manacas">
        <JardimDosManacas />
      </Route>
    </>
  );
}

export default MundoApto;
