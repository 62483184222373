import styled, { css } from "styled-components";
import { Tooltip } from "react-leaflet";

export const TooltipWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Point = styled.p`
  font-weight: 500;
  font-size: 13px;
  ${({ color }) => css`
    color: ${color};
  `}
`;

export const Distance = styled.p`
  font-weight: 500;
  font-size: 13px;
  margin-left: 5px;
  color: #9fa6b2;
  ${({ color }) => css`
    color: ${color};
  `}
`;

const tooltipModifier = {
  top: (tooltipStyle) => css`
    border-top-color: ${!!tooltipStyle ? tooltipStyle.background : "#27303f"};
  `,
  left: (tooltipStyle) => css`
    border-left-color: ${!!tooltipStyle ? tooltipStyle.background : "#27303f"};
  `,
  right: (tooltipStyle) => css`
    border-right-color: ${!!tooltipStyle ? tooltipStyle.background : "#27303f"};
  `,
  bottom: (tooltipStyle) => css`
    border-bottom-color: ${!!tooltipStyle
      ? tooltipStyle.background
      : "#27303f"};
  `,
  accentColor: (color) => css`
    border: ${color} solid 2px;
  `,
  tooltipStyle: (tooltipStyle) => css`
    background-color: ${tooltipStyle.background};
  `,
};

export const TooltipCustom = styled(Tooltip)`
  ${({ direction, accentColor, tooltipStyle }) => css`
    &:before {
      ${direction === "top" && tooltipModifier.top(tooltipStyle)}
      ${direction === "bottom" && tooltipModifier.bottom(tooltipStyle)}
      ${direction === "left" && tooltipModifier.left(tooltipStyle)}
      ${direction === "right" && tooltipModifier.right(tooltipStyle)}
    }
    ${!!accentColor && tooltipModifier.accentColor(accentColor)}
    ${!!tooltipStyle && tooltipModifier.tooltipStyle(tooltipStyle)}
  `}
`;
