import React, { useState } from "react";
import { createContext } from "react";
import locationsMock from "./config.json";

export const LocationsContext = createContext();

const AppProvider = ({ children }) => {
  const locations = locationsMock.locations;
  const [categories, setCategories] = useState(locationsMock.categories);
  const mapConfig = locationsMock.map;
  const center = locationsMock.center;
  const discount = [0.0003, 0];
  const titlePage = locationsMock.title;
  const locationsConfig = locationsMock;

  const tileLayer = `https://api.mapbox.com/styles/${mapConfig.tileLayer}/tiles/256/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_KEY}`;

  function toggleCategoryVisibility(index) {
    const category = categories[index];

    for (var key in categories) {
      if (categories.hasOwnProperty(key)) {
        if(
          categories[key].fixed !== true
        ){
          categories[key].active = false
          console.log(categories[key])

          setCategories({...categories}, categories[key]);
        }
      }
    }
    
    setCategories({
      ...categories,
      [index]: {
        ...category,
        active: !category.active,
      },
    });
  }

  return (
    <LocationsContext.Provider
      value={{
        locations,
        categories,
        toggleCategoryVisibility,
        mapConfig,
        center,
        tileLayer,
        discount,
        titlePage,
        locationsConfig,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
};

export default AppProvider;
