import React from "react";

import { Route } from "react-router";

import Residencial from "./residencial";
import Studio from "./studio";

function MontKlabin() {
  return (
    <>
      <Route path="/montklabin/residencial">
        <Residencial />
      </Route>
      <Route path="/montklabin/studio">
        <Studio />
      </Route>
    </>
  );
}

export default MontKlabin;
