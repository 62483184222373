import Leaflet from "leaflet";
import { useRef } from "react";
import { Marker } from "react-leaflet";

import * as S from "./styles";

function MarkerMap({
  location,
  icon,
  classNameTooltip,
  discount,
  tooltipDirection,
  accentColor,
  tooltipStyle,
  ...rest
}) {
  const tooltipRef = useRef();

  const mapPinIcon = (icon) =>
    Leaflet.icon({
      iconUrl: icon,
      iconSize: [48, 48],
      iconAnchor: [25, 25],
    });

  const lat = location.latlng[0];
  const lng = location.latlng[1];

  const tooltipOffset = (tooltipDirection) => {
    switch (tooltipDirection) {
      case "top":
        return [0, -30];
      case "left":
        return [-30, 0];
      case "right":
        return [30, 0];
      case "bottom":
        return [0, 30];
      default:
        return [0, -30];
    }
  };

  return (
    <Marker
      {...rest}
      className="map-marker"
      icon={mapPinIcon(icon)}
      position={[lat, lng]}
      title={location.point}
    >
      <S.TooltipCustom
        className={classNameTooltip || ""}
        direction={tooltipDirection || "top"}
        offset={tooltipOffset(tooltipDirection)}
        permanent
        ref={tooltipRef}
        zoomAnimated={false}
        sticky={true}
        opacity={1}
        accentColor={accentColor}
        tooltipStyle={tooltipStyle}
      >
        <S.TooltipWrapper>
          <S.Point color={tooltipStyle?.color}>{location.point}</S.Point>
          <S.Distance color={tooltipStyle?.complementaryColor}>{location.distance}</S.Distance>
        </S.TooltipWrapper>
      </S.TooltipCustom>
    </Marker>
  );
}

export default MarkerMap;
