import React from "react";
import FloatingMenu from "../../../components/FloatingMenu";
import MapView from "../../../components/MapView";
import Provider, { LocationsContext } from "./context";

import logo from "./assets/logo-circle.svg";

import LocalStyles from "./styles";
import { getIcon, getIconInline } from "./icons";

const Logo = () => (
  <div
    style={{
      height: "85px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 10px",
    }}
  >
    <svg
      width="43"
      height="34"
      viewBox="0 0 43 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.2857 7.36489C32.6222 5.45679 34.7517 3.72215 36.9107 1.9586C37.5614 1.43821 38.1825 0.860001 38.8923 0.455253C40.6669 -0.585529 42.5597 0.33961 42.8555 2.30553C42.9442 2.91265 42.9738 3.54869 42.9738 4.18472C42.9738 12.7133 42.9738 21.242 42.9738 29.7706C42.9738 30.4356 43.0921 31.1872 42.8259 31.7654C42.5006 32.4882 41.9386 33.3844 41.288 33.5579C40.6373 33.7314 39.6021 33.2399 39.0402 32.7195C38.3895 32.1124 38.0938 31.1583 37.6797 30.3488C35.0179 25.1449 32.3856 19.9121 29.7534 14.7082C29.5463 14.2745 29.2802 13.8987 28.9548 13.3205C26.1747 15.6044 23.5424 17.7438 20.9102 19.8832C15.5865 24.2487 10.2628 28.6142 4.93918 32.9797C3.9336 33.7892 2.86886 34.3385 1.56752 33.7603C0.236607 33.1821 0 31.9967 0 30.7536C0 21.5889 0 12.4532 0 3.28849C0 1.9586 0.207031 0.744359 1.74498 0.281789C3.10547 -0.15187 3.99275 0.599806 4.7913 1.49603C6.71373 3.63542 8.66574 5.74589 10.529 7.91419C11.1797 8.66586 11.6825 8.83933 12.5698 8.14547C15.5569 5.74589 18.6033 3.46196 21.62 1.14911C24.0748 -0.758992 26.3521 -0.267512 27.7718 2.45008C28.5703 4.01126 29.3689 5.60134 30.2857 7.36489ZM3.87444 28.7298C4.19978 28.6142 4.37724 28.5853 4.46596 28.5274C11.8304 22.4851 19.1948 16.4717 26.5592 10.4294C26.8254 10.1981 27.1211 9.591 27.0028 9.35972C26.1747 7.56726 25.2578 5.83263 24.3114 3.92453C23.6903 4.32927 23.2171 4.61838 22.8326 4.9364C20.2003 6.96014 17.5681 8.98388 14.9654 11.0365C12.1261 13.2337 10.2924 13.0892 7.86719 10.4583C6.65458 9.12844 5.41239 7.76964 3.9336 6.15064C3.87444 13.8987 3.87444 21.242 3.87444 28.7298ZM39.0994 5.13877C38.5078 5.57243 38.0938 5.83263 37.7389 6.12173C36.2009 7.3938 34.6925 8.69478 33.0954 9.93793C32.2377 10.574 32.0899 11.1522 32.5927 12.1062C34.4855 15.6911 36.2601 19.3339 38.1234 22.9477C38.3304 23.3525 38.5966 23.7283 38.8332 24.1041C38.9219 24.0752 39.0106 24.0463 39.0994 24.0174C39.0994 17.8305 39.0994 11.6726 39.0994 5.13877Z"
        fill="white"
      />
    </svg>
  </div>
);

function Pacaembu() {
  return (
    <Provider>
      <MapView
        locationsContext={LocationsContext}
        logo={logo}
        getIcon={getIcon}
      >
        <FloatingMenu
          locationsContext={LocationsContext}
          logo={<Logo />}
          getIconInline={getIconInline}
        />
      </MapView>

      <LocalStyles />
    </Provider>
  );
}

export default Pacaembu;
