import React from "react";

import { Route } from "react-router";

import GiardinoGolf from "./giardino-golf/index";

function MundoApto() {
  return (
    <>
      <Route path="/bacaba/giardino-golf">
        <GiardinoGolf />
      </Route>
    </>
  );
}

export default MundoApto;
