import academias from "./assets/icons/academias.svg";
import padaria from "../assets/icons/padarias.svg";
import farmacia from "../assets/icons/farmacias.svg";
import bancos from "../assets/icons/bancos.svg";
import estacao from "../assets/icons/metro.svg";
import metroazul from "../assets/icons/metroazul.svg";
import metrolilas from "../assets/icons/metrolilas.svg";
import facilidades from "../assets/icons/facilidades.svg";
import metrovermelho from "../assets/icons/metrovermelho.svg";

import shoppings from "../../../assets/icons/shoppings.svg";
import hospitais from "../../../assets/icons/hospitais.svg";
import aeroporto from "../../../assets/icons/aeroporto.svg";
import escola from "../../../assets/icons/escola.svg";
import gastronomia from "../../../assets/icons/gastronomia.svg";
import mercados from "../../../assets/icons/mercados.svg";
import viasprincipais from "../../../assets/icons/viasprincipais.svg";

export const getIconInline = {
  gastronomia: <img src={gastronomia}></img>,
  padaria: <img src={padaria}></img>,
  escola: <img src={escola}></img>,
  academias: <img src={academias}></img>,
  mercados: <img src={mercados}></img>,
  farmacia: <img src={farmacia}></img>,
  bancos: <img src={bancos}></img>,
  viasprincipais: <img src={viasprincipais}></img>,
  metroazul: <img src={metroazul}></img>,
  metrolilas: <img src={metrolilas}></img>,
  metrovermelho: <img src={metrovermelho}></img>,
  facilidades: <img src={facilidades}></img>,
  shoppings: <img src={shoppings}></img>,
  hospitais: <img src={hospitais}></img>,
  aeroporto: <img src={aeroporto}></img>,
};

export const getIcon = {
  gastronomia: gastronomia,
  padaria: padaria,
  escola: escola,
  academias: academias,
  mercados: mercados,
  farmacia: farmacia,
  bancos: bancos,
  estacao: estacao,
  viasprincipais: viasprincipais,
  metroazul: metroazul,
  metrolilas: metrolilas,
  metrovermelho: metrovermelho,
  facilidades: facilidades,
  shoppings: shoppings,
  hospitais: hospitais,
  aeroporto: aeroporto,
};
