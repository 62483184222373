import React from "react";
import FloatingMenu from "../../../components/FloatingMenu";
import MapView from "../../../components/MapView";
import Provider, { LocationsContext } from "./context";

import { getIcon, getIconInline } from "./icons";

import LocalStylesBlue from "../styles/blue";

import logoBlue from "../assets/tecnisa-azul.png";

const LogoBlue = () => (
  <div
    style={{
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 10px",
    }}
  >
    <img src={logoBlue} />
  </div>
);

function TecnisaHighlights() {
  return (
    <Provider>
      <MapView
        locationsContext={LocationsContext}
        logo={logoBlue}
        getIcon={getIcon}
      >
        <FloatingMenu
          locationsContext={LocationsContext}
          logo={<LogoBlue />}
          getIconInline={getIconInline}
          sideMenu
        />
      </MapView>
      <LocalStylesBlue />
    </Provider>
  );
}

export default TecnisaHighlights;
