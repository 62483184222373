import React from "react";
import FloatingMenu from "../../components/FloatingMenu";
import MapView from "../../components/MapView";
import Provider, { LocationsContext } from "./context";

import logo from "./assets/logo-icon.png";
import logoJardins from "./assets/logo.jpg";

import LocalStyles from "./styles";
import { getIcon, getIconInline } from "./icons";

const Logo = () => <img style={{ height: "115px" }} src={logoJardins} />;

function Caparao() {
  return (
    <Provider>
      <MapView
        locationsContext={LocationsContext}
        logo={logo}
        getIcon={getIcon}
      >
        <FloatingMenu
          locationsContext={LocationsContext}
          logo={<Logo />}
          getIconInline={getIconInline}
        />
      </MapView>

      <LocalStyles />
    </Provider>
  );
}

export default Caparao;
