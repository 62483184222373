import React from "react";

import { Route } from "react-router";

import AlamedaPetropolis from "./alameda-petropolis/index";
import SarmentoDeBeires from "./sarmento-de-beires/index";
import QuirinoDeLima from "./quirino-de-lima/index";
import Atibaia from "./atibaia/index";
import Bonifacio from "./bonifacio/index";
import Brasilio from "./brasilio/index";
import Leopoldina from "./leopoldina/index";
import Fongaro from "./fongaro/index";

function MundoApto() {
  return (
    <>
      <Route path="/carvalho-e-santos/alameda-petropolis">
        <AlamedaPetropolis />
      </Route>

      <Route path="/carvalho-e-santos/sarmento-de-beires">
        <SarmentoDeBeires />
      </Route>

      <Route path="/carvalho-e-santos/quirino-de-lima">
        <QuirinoDeLima />
      </Route>

      <Route path="/carvalho-e-santos/atibaia">
        <Atibaia />
      </Route>

      <Route path="/carvalho-e-santos/bonifacio">
        <Bonifacio />
      </Route>

      <Route path="/carvalho-e-santos/brasilio">
        <Brasilio />
      </Route>

      <Route path="/carvalho-e-santos/leopoldina">
        <Leopoldina />
      </Route>

      <Route path="/carvalho-e-santos/fongaro">
        <Fongaro />
      </Route>
    </>
  );
}

export default MundoApto;
