import { createGlobalStyle } from "styled-components";

const LocalStyles = createGlobalStyle`
  * {
      font-family: Arial, Helvetica, sans-serif
  }

  nav img[style] {
    height: 90px !important;
  }
`;

export default LocalStyles;
